$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');
	
	/* BUTTON HEADER SCROLL */
	$(".button").click(function() {
	    $('html, body').animate({
	        scrollTop: $(".navi-container").offset().top
	    }, 2000);
	});
	/**
	 * ADD CLASS FIXED ON NAVIGATION
	 */
	var stickyNavTop = $('.navi-container').offset().top;
			 
	var stickyNav = function(){
	var scrollTop = $(window).scrollTop();
				
	if (scrollTop > stickyNavTop) { 
			$('.navi-container').addClass('fixed');
	} else {
			$('.navi-container').removeClass('fixed'); 
	}
	};
	 
	stickyNav();
	 
	$(window).scroll(function() {
			stickyNav();
	});
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/